import {
	Button,
	Center,
	Checkbox,
	Flex,
	FormControl,
	FormLabel,
	HStack,
	Image,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

const MAX_WIDTH = '720px';
const PLACEHOLDER_FONT = 'rgb(177, 157, 144)';
const PRIMARY_FONT = 'rgb(132, 65, 22)';
const PRIMARY_DARK = 'rgb(142, 60, 0)';
const PRIMARY_DARKER = 'rgb(113, 47, 0)';
const INPUT_BACKGROUND = 'rgb(236, 209, 192)';

export const CouponPage = () => (
	<VStack w="full" h="100vh">
		<Flex
			maxW={MAX_WIDTH}
			w="full"
			flexDirection={'column'}
			gap="0"
			backgroundColor={'rgb(169, 168, 113)'}
			backgroundSize={'contain'}
			fontFamily={'katuri'}
		>
			<Flex
				w="full"
				flexDirection={'column'}
				gap="0"
				backgroundImage={'/images/bg.jpeg'}
				backgroundRepeat="no-repeat"
				backgroundAttachment={'fixed'}
				backgroundPosition={'center bottom'}
			>
				<VStack w={'full'} minH={'100vh'} justifyContent={'center'} py={4}>
					<Center
						w={`${613 / 2}px`}
						h={`${175 / 2}px`}
						backgroundImage={'/images/sub-title.png'}
						backgroundSize={'contain'}
						backgroundRepeat={'no-repeat'}
						backgroundPosition={'center'}
					>
						<Text fontSize={'3xl'} whiteSpace={'nowrap'} wordBreak={'keep-all'} color={PRIMARY_DARKER}>
							쿠폰 사용하기
						</Text>
					</Center>
					<InputForm />
				</VStack>
			</Flex>
		</Flex>
	</VStack>
);

const InputForm = () => {
	const [isLoadingUpload, setIsLoadingUpload] = useState(false);
	const [couponCodeValue, setCouponCodeValue] = useState('');
	const { isOpen: isSuccessModalOpen, onOpen: onSuccessModalOpen, onClose: onSuccessModalClose } = useDisclosure();
	const toast = useToast();
	const {
		handleSubmit,
		register,
		control,
		reset,
		formState: { errors },
	} = useForm();

	const handleSubmitImpl = (data: any) => {
		const { userId, couponCode, userConfirm } = data;
		if (!userId) {
			toast({
				title: `'쿠폰을 적용할 계정을 알려주세요.`,
				status: 'error',
				isClosable: true,
			});
			return;
		}
		if (!couponCode) {
			toast({
				title: `'쿠폰 코드를 입력해 주세요.`,
				status: 'error',
				isClosable: true,
			});
			return;
		}
		if (!userConfirm) {
			toast({
				title: `쿠폰 코드와 계정 식별 번호를 모두 입력했다면 체크박스를 체크해주세요.`,
				status: 'error',
				isClosable: true,
			});
			return;
		}
		setIsLoadingUpload(true);
		toast({
			title: `계정에 쿠폰을 등록하는 중이에요!`,
			status: 'info',
			isClosable: true,
		});
		axios
			.put(`${process.env.REACT_APP_API_BASE_URL}/coupons/${couponCode}/usage`, { userId })
			.then(response => {
				setCouponCodeValue(couponCode);
				onSuccessModalOpen();
				reset();
			})
			.catch(error => {
				if (error.response) {
					// 오류 응답이 있는 경우
					toast({
						title: error.response.data.error,
						status: 'error',
						isClosable: true,
					});
				} else {
					// 오류 응답이 없는 경우 (예: 네트워크 오류 등)
					toast({
						title: error.message,
						status: 'error',
						isClosable: true,
					});
				}
			})
			.finally(() => {
				setIsLoadingUpload(false);
			});
	};

	return (
		<Center w="full" px={[4, 8]} py={8}>
			<VStack
				boxShadow={'2xl'}
				w="full"
				maxW="400px"
				justifyContent={'space-between'}
				bg={'rgb(253, 236, 218)'}
				rounded={'xl'}
				px={[6, 8, 12]}
				py={10}
			>
				<form id={'coupon-code-form'} onSubmit={handleSubmit(handleSubmitImpl)} style={{ width: '100%' }}>
					<VStack w="full" color={PRIMARY_FONT}>
						<VStack w="full" alignItems={'flex-start'}>
							<Image className="coupon-image" ml={8} mt={-16} mb={-10} w="full" maxW="300px" src="/images/eat-rose.gif" />
							<FormControl>
								<FormLabel>계정 식별 번호</FormLabel>
								<Input
									placeholder={"'설정 > 계정 식별 번호'에서 확인"}
									required
									rounded={'full'}
									fontSize={'sm'}
									disabled={isLoadingUpload}
									bg={INPUT_BACKGROUND}
									borderColor={PRIMARY_DARK}
									_hover={{ borderColor: PRIMARY_DARKER }}
									_placeholder={{ color: PLACEHOLDER_FONT }}
									focusBorderColor={PRIMARY_DARKER}
									aria-label="userId"
									{...register('userId', { required: '계정 식별 번호를 입력해 주세요.' })}
								/>
							</FormControl>
							<FormControl>
								<FormLabel>쿠폰 코드</FormLabel>
								<Input
									placeholder={'THANKYOU'}
									required
									rounded={'full'}
									fontSize={'sm'}
									disabled={isLoadingUpload}
									bg={INPUT_BACKGROUND}
									borderColor={PRIMARY_DARK}
									_hover={{ borderColor: PRIMARY_DARKER }}
									_placeholder={{ color: PLACEHOLDER_FONT }}
									focusBorderColor={PRIMARY_DARKER}
									aria-label="couponCode"
									{...register('couponCode', { required: '쿠폰 코드를 입력해 주세요.' })}
								/>
							</FormControl>
							<HStack w="full" justifyContent={'flex-end'} spacing={2}>
								<Checkbox
									colorScheme="orange"
									bg={INPUT_BACKGROUND}
									borderColor={PRIMARY_DARK}
									disabled={isLoadingUpload}
									{...register('userConfirm')}
								/>
								<Text color={isLoadingUpload ? PLACEHOLDER_FONT : PRIMARY_FONT} fontSize={'sm'}>
									쿠폰 코드와 계정 식별 번호를 모두 확인했습니다.
								</Text>
							</HStack>
						</VStack>
						<VStack w="full" spacing={4}>
							<Button
								type="submit"
								rounded={'full'}
								w="full"
								color="white"
								backgroundColor={PRIMARY_DARK}
								_hover={{ bg: PRIMARY_DARKER }}
								_active={{ bg: PRIMARY_DARKER }}
								isLoading={isLoadingUpload}
							>
								사용하기
							</Button>
						</VStack>
					</VStack>
				</form>
			</VStack>

			<Modal isOpen={isSuccessModalOpen} onClose={onSuccessModalClose} size={'md'}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader fontFamily={'katuri'} fontWeight={'normal'}>
						쿠폰 사용 완료
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody fontFamily={'katuri'}>
						<VStack spacing={0}>
							<Image minW="200px" w="200px" maxW="200px" src="/images/cgb.png" pb={4} />
							<Text fontSize={'lg'} textAlign={'center'}>
								쿠폰{`(${couponCodeValue})`}이 사용됐어요. 메일함을 확인해주세요!
							</Text>
						</VStack>
					</ModalBody>
					<ModalFooter>
						<Button
							backgroundColor={PRIMARY_DARK}
							_hover={{ bg: PRIMARY_DARKER }}
							_active={{ bg: PRIMARY_DARKER }}
							color="white"
							onClick={onSuccessModalClose}
						>
							확인
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Center>
	);
};
