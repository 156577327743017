import { ChakraProvider } from '@chakra-ui/react';
import { CustomGlobalStyle, Fonts, extendedTheme } from './theme';
import { LandingPage } from './pages/LandingPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { NavBar } from './layouts/navbar/Navbar';
import { Footer } from './layouts/footer/Footer';
import { CouponPage } from './pages/CouponPage';
import { PrivacyEnPage, PrivacyKrPage } from './pages/PrivacyPage';
import { TermsOfServiceEnPage, TermsOfServiceKrPage } from './pages/TermsOfServicePage';
import { OpensourceUsagePage } from './pages/OpensourceUsagePage';

export const App = () => (
	<BrowserRouter>
		<Fonts />
		<ChakraProvider theme={extendedTheme}>
			<CustomGlobalStyle />
			<Routes>
				<Route
					path="/"
					element={
						<>
							<NavBar />
							<LandingPage />
							<Footer />
						</>
					}
				/>
				<Route path="/coupon" element={<CouponPage />} />
				<Route path="/privacy-eng" element={<PrivacyEnPage />} />
				<Route path="/privacy" element={<PrivacyKrPage />} />
				<Route path="/terms-of-service-eng" element={<TermsOfServiceEnPage />} />
				<Route path="/terms-of-service" element={<TermsOfServiceKrPage />} />
				<Route path="/oss-notice" element={<OpensourceUsagePage />} />
			</Routes>
		</ChakraProvider>
	</BrowserRouter>
);
