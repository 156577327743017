export default function AppStoreBadge() {
	return (
		<svg width="100%" height="100%" viewBox="2 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#prefix__clip0)">
				<path
					d="M33.476 21.982c-.033-3.58 2.932-5.323 3.067-5.404-1.678-2.448-4.28-2.782-5.195-2.81-2.186-.23-4.306 1.309-5.42 1.309-1.135 0-2.85-1.286-4.697-1.248-2.378.037-4.602 1.413-5.822 3.551-2.518 4.359-.64 10.765 1.772 14.288 1.207 1.725 2.617 3.652 4.462 3.584 1.806-.074 2.48-1.15 4.66-1.15 2.158 0 2.791 1.15 4.674 1.107 1.937-.031 3.158-1.733 4.322-3.475 1.394-1.977 1.954-3.925 1.977-4.025-.046-.016-3.764-1.434-3.8-5.727zM29.92 11.451c.971-1.214 1.636-2.867 1.451-4.543-1.405.062-3.163.972-4.175 2.16-.896 1.046-1.696 2.762-1.49 4.375 1.58.118 3.2-.796 "
					fill="#000000"
				/>
			</g>
			<defs>
				<clipPath id="prefix__clip0">
					<path fill="#000000" d="M0 0h150v44.444H0z" />
				</clipPath>
			</defs>
		</svg>
	);
}
