import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, HStack, Heading, IconButton, Image, Text, VStack, useBreakpointValue } from '@chakra-ui/react';
import { useCallback, useState } from 'react';

const slides = [
	{
		image: '/images/who-is-chaggebi.jpeg',
		header: '차깨비',
		content: (
			<>
				차깨비란{' '}
				<Text as="u" fontWeight={'bold'}>
					차에서 태어난 작은 도깨비
				</Text>
				들을 말합니다. 차를 만드는 것이 특기이며, 차깨비가 만든 차는 일반 차보다 더 맛있고 힘이 난다고 합니다. 홍차 차깨비부터 천사 차깨비까지, 각자가
				좋아하는 차에 따라 다양한 모습을 하고 있지만, 차를 향한 애정과 자신감은 어느 차깨비든 마찬가지입니다.
			</>
		),
	},
	{
		image: '/images/cafe-close.jpeg',
		header: '게임의 무대',
		content: (
			<>
				{'<차깨비 찻집>'}의 이야기는 머나먼{' '}
				<Text as="u" fontWeight={'bold'}>
					말루이 대륙
				</Text>
				에서 펼쳐집니다. 그곳은 신비한 식물들이 자라며, 동물들이 말을 하는 곳입니다. 아름다운 이야기가 그려진 동화 속처럼 말이죠. 지역마다 특색이 달라
				서로 고립되어 있는 경향이 크지만,{' '}
				<Text as="u" fontWeight={'bold'}>
					모험가 하르퍼쉬
				</Text>
				가 대륙의 모든 지리와 길을 기록한 세계지도를 만든 이후에는 마음만 먹으면 어디든 갈 수 있기도 합니다. 당신은 이동식 찻집을 이끌고 다양한 지역을
				오갈 수 있습니다. 어떤 지역에서는 당신을 반기는 한편, 어떤 지역에서는 낯선 당신을 경계하기도 합니다. 어느 곳이든 차깨비와 차만 있다면 주민들의
				마음을 사로잡을 수 있을 것입니다.
			</>
		),
	},
	{
		image: '/images/cafe-tycoon.jpeg',
		header: '찻집 타이쿤',
		content: (
			<>
				{'<차깨비 찻집>'}은 육성 시뮬레이션과 타이쿤 장르를 결합한 아기자기한 힐링 게임입니다. 플레이어는 귀여운 차깨비들을 돌보면서, 함께{' '}
				<Text as="u" fontWeight={'bold'}>
					차를 제조하고 판매하여
				</Text>{' '}
				찻집을 성공적으로 이끌어 나가야 합니다. 환경에 따라 조금씩 성장하는 차깨비들과 함께 말루이 대륙 곳곳을 누비면서, 다양한 손님들을 만나고 가슴
				따뜻한 이야기들을 만들어 나가세요!
			</>
		),
	},
];

export const Features = () => {
	return (
		<Center as="section" id="features" w="full" bg={'white'} py={{ base: 12, md: 16 }} fontFamily={'notosans'}>
			<VStack w="full" maxW={'800px'} borderStyle={'solid'} borderColor={'gray.700'} spacing={8}>
				<Text
					color={'black'}
					lineHeight={1.2}
					fontSize={useBreakpointValue({ base: '4xl', md: '5xl' })}
					fontFamily={'katuri'}
					boxShadow={'inset 0 -20px 0 rgba(255, 220, 219, 0.8)'}
					px={2}
				>
					게임 소개
				</Text>
				<Carousel />
			</VStack>
		</Center>
	);
};

const Carousel = () => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const gap = useBreakpointValue({ base: 3, md: 2 }) ?? 3;
	const glance = useBreakpointValue({ base: 0, md: 5 }) ?? 0;
	const unfocusSize = 93;
	const focusSize = 100 - (glance + gap) * 2;
	const carouselStart = unfocusSize - glance;
	const carouselHop = gap + unfocusSize;
	const slidesCount = slides.length;

	const prevSlide = useCallback(() => {
		setCurrentSlide(s => (s === 0 ? slidesCount - 1 : s - 1));
	}, [slidesCount]);

	const nextSlide = useCallback(() => {
		setCurrentSlide(s => (s === slidesCount - 1 ? 0 : s + 1));
	}, [slidesCount]);
	return (
		<VStack w="full" alignItems="center" justifyContent="center">
			<HStack w="full" justifyContent={'center'}>
				<VStack w="full" gap={4}>
					<Flex w="full" pos="relative" overflow="hidden">
						<Flex w="full" transition="all .5s" ml={`-${currentSlide * carouselHop + carouselStart}%`} gap={`${gap}%`} alignItems={'center'}>
							<CarouselItemEnd unfocusSize={unfocusSize} focusSize={focusSize} />
							{slides.map((slide, index) => {
								return (
									<CarouselItem
										key={'slide-' + slide.header}
										{...slide}
										unfocusSize={unfocusSize}
										focusSize={focusSize}
										active={currentSlide === index}
										onClick={() => setCurrentSlide(index)}
									/>
								);
							})}
							<CarouselItemEnd unfocusSize={unfocusSize} focusSize={focusSize} />
						</Flex>
						<Flex
							display={{ base: 'none', md: 'block' }}
							pos={'absolute'}
							left="0"
							w="16px"
							h="100%"
							bg={'linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)'}
						/>
						<Flex
							display={{ base: 'none', md: 'block' }}
							pos={'absolute'}
							right="0"
							w="16px"
							h="100%"
							bg={'linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)'}
						/>
					</Flex>

					{/* Navigation - mobile */}
					<HStack w="full" justify="center" gap={4} px={3} display={{ base: 'flex', md: 'none' }}>
						<IconButton
							aria-label="prev"
							size="lg"
							bg={'blackAlpha.300'}
							borderRadius={'full'}
							icon={<ChevronLeftIcon color="white" fontSize={'2rem'} />}
							_hover={{ bg: 'blackAlpha.500' }}
							onClick={prevSlide}
						/>
						<IconButton
							aria-label="next"
							size="lg"
							bg={'blackAlpha.300'}
							borderRadius={'full'}
							icon={<ChevronRightIcon color="white" fontSize={'2rem'} />}
							_hover={{ bg: 'blackAlpha.500' }}
							onClick={nextSlide}
						/>
					</HStack>
					{/* Navigation - desktop */}
					<HStack w="full" justify="center" gap={1} px={3} display={{ base: 'none', md: 'flex' }}>
						<IconButton
							aria-label="prev"
							variant={'ghost'}
							borderRadius={'full'}
							icon={
								<ChevronLeftIcon
									transition="all .5s"
									color="gray.300"
									fontSize={'1.5rem'}
									_hover={{
										color: 'gray.600',
									}}
								/>
							}
							_hover={{ bg: 'transparent' }}
							onClick={prevSlide}
						/>
						{slides.map((slide, index) => {
							return (
								<Box
									as="button"
									key={'slide-to-' + slide.header}
									transition="all .5s"
									w={currentSlide === index ? '30px' : '8px'}
									h="8px"
									bgColor={currentSlide === index ? 'gray.400' : 'gray.200'}
									borderColor={'gray.600'}
									borderRadius={'full'}
									cursor={'pointer'}
									onClick={() => setCurrentSlide(index)}
									_hover={{
										bgColor: currentSlide === index ? 'gray.400' : 'gray.300',
									}}
								/>
							);
						})}
						<IconButton
							aria-label="next"
							variant={'ghost'}
							borderRadius={'full'}
							icon={
								<ChevronRightIcon
									transition="all .5s"
									color="gray.300"
									fontSize={'1.5rem'}
									_hover={{
										color: 'gray.600',
									}}
								/>
							}
							_hover={{ bg: 'transparent' }}
							onClick={nextSlide}
						/>
					</HStack>
				</VStack>
			</HStack>
		</VStack>
	);
};

function CarouselItemEnd({ unfocusSize, focusSize }: { unfocusSize: number; focusSize: number }) {
	return (
		<Box
			flex="none"
			transition="all .5s"
			boxSize={`${unfocusSize}%`}
			opacity={'50%'}
			filter={'blur(2px)'}
			borderWidth="1px"
			borderRadius="lg"
			overflow="hidden"
			alignItems="center"
			justifyContent="center"
			bg={'gray.200'}
		/>
	);
}

function CarouselItem({
	unfocusSize,
	focusSize,
	image,
	header,
	content,
	active,
	onClick,
}: {
	unfocusSize: number;
	focusSize: number;
	image: string;
	header: string;
	content: any;
	active: boolean;
	onClick: any;
}) {
	return (
		<Box
			flex="none"
			transition="all .5s"
			boxSize={`${active ? focusSize : unfocusSize}%`}
			minH={`${active ? '100' : unfocusSize}%`}
			opacity={active ? '100%' : '50%'}
			filter={active ? '' : 'blur(2px)'}
			borderWidth="1px"
			borderColor={'gray.300'}
			borderRadius={{ base: 'none', md: 'lg' }}
			overflow="hidden"
			alignItems="center"
			justifyContent="center"
			onClick={onClick}
			cursor={active ? '' : 'pointer'}
		>
			<Image w="full" src={image} h={'40vh'} maxH={'40vh'} objectFit="cover" />
			<VStack w="full" p={4}>
				<Heading w="full" size="lg" mb={2}>
					{header}
				</Heading>
				<Text fontSize={useBreakpointValue({ base: 'lg', md: 'lg' })} wordBreak={'keep-all'}>
					{content}
				</Text>
			</VStack>
		</Box>
	);
}
