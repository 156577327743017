import { Center, Flex, Image, SimpleGrid, VStack, useBreakpointValue } from '@chakra-ui/react';
import { GotoAppStore, GotoGooglePlay, GotoOneStore } from './GotoStores';

export const Hero = () => {
	return (
		<Flex
			as="section"
			w={'full'}
			h={'100vh'}
			minH="100vh"
			backgroundImage={'linear-gradient(rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.10)), url("/images/garden-chaggebi.jpeg")'}
			backgroundSize={'cover'}
			backgroundPosition={'center center'}
			overflowY={'hidden'}
		>
			<VStack w={'full'} justify={'center'} px={useBreakpointValue({ base: 4, md: 8 })} pt={'5rem'}>
				<VStack w={useBreakpointValue({ base: 'full', md: 'full' })} px={2} align={'center'} spacing={2}>
					<VStack w="full" pt={'10rem'} zIndex={1}>
						<Center w={'full'} py="2" mb={8}>
							<Image
								src="/images/title.png"
								w={useBreakpointValue({ base: 'full', md: 'xl' })}
								maxW="540px"
								px={useBreakpointValue({ base: 0, md: 0 })}
								filter={'drop-shadow(0px 10px 10px #000)'}
							></Image>
						</Center>
						<VStack w="full" maxW={'1280px'} py={useBreakpointValue({ base: 2, md: 4 })}>
							<SimpleGrid columns={{ base: 2, md: 3 }} spacing={4} fontFamily={'notosans'}>
								<GotoAppStore />
								<GotoGooglePlay />
								<GotoOneStore />
							</SimpleGrid>
						</VStack>
					</VStack>
				</VStack>
			</VStack>
		</Flex>
	);
};
