import { Box, Center, Image, Stack, Text, VStack, useBreakpointValue } from '@chakra-ui/react';

export const Synopsys = () => {
	return (
		<Center as="section" id="synopsys" w="full" bg={'gray.50'} py={{ base: 12, md: 16 }} fontFamily={'notosans'}>
			<VStack w="full" maxW={'800px'} borderStyle={'solid'} borderColor={'gray.700'} spacing={8}>
				<Text
					color={'black'}
					lineHeight={1.2}
					fontSize={useBreakpointValue({ base: '4xl', md: '5xl' })}
					fontFamily={'katuri'}
					boxShadow={'inset 0 -20px 0 rgba(255, 220, 219, 0.8)'}
					px={2}
				>
					게임 개요
				</Text>
				<Stack w="full" gap={4} flexDirection={{ base: 'column', md: 'row' }}>
					<Box w="full">
						<Image w="full" src="/images/poor.jpeg" />
					</Box>
					<VStack
						w="full"
						justifyContent={'center'}
						color={'black'}
						lineHeight={1.5}
						fontSize={useBreakpointValue({ base: 'xl', md: 'xl' })}
						wordBreak={'keep-all'}
						whiteSpace={'nowrap'}
					>
						<Text>
							당신은{' '}
							<Text as="u" fontWeight={'bold'}>
								이동식 찻집
							</Text>
							의 주인장입니다.
						</Text>
						<Text>하지만 왜인지 장사가 잘 되지 않습니다....</Text>
						<Text>가게를 접을까 생각하던 어느 날,</Text>
						<Text>
							<Text as="u" fontWeight={'bold'}>
								이상하고 작은 손님
							</Text>
							이 찾아오게 되는데....
						</Text>
					</VStack>
				</Stack>
			</VStack>
		</Center>
	);
};
