import { Hero } from '../components/hero/Hero';
import { Features } from '../components/features/Features';
import { MalmalStudio } from '../components/malmal-studio/MalmalStudio';
import { Synopsys } from '../components/synopsys/Synopsys';

export const LandingPage = () => (
	<>
		<Hero />
		<Synopsys />
		<Features />
		<MalmalStudio />
	</>
);
