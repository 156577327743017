import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Button, Center, Image, Stack, Text, VStack, useBreakpointValue } from '@chakra-ui/react';

export const MalmalStudio = () => {
	return (
		<Center as="section" id="malmal-studio" w="full" bg={'gray.50'} py={{ base: 12, md: 16 }} fontFamily={'notosans'} px={4}>
			<VStack w="full" spacing={8}>
				<Text
					color={'black'}
					lineHeight={1.2}
					fontSize={useBreakpointValue({ base: '4xl', md: '5xl' })}
					fontFamily={'katuri'}
					boxShadow={'inset 0 -20px 0 rgba(255, 220, 219, 0.8)'}
					px={2}
				>
					개발자 소개
				</Text>
				<VStack
					borderRadius={'2xl'}
					px={{ base: 8, md: 16 }}
					py={{ base: 6, md: 8 }}
					w="full"
					maxW={'920px'}
					borderWidth={'1px'}
					borderStyle={'solid'}
					borderColor={'gray.200'}
					bg={'white'}
					spacing={4}
					justifyContent={'flex-start'}
					alignItems={'flex-start'}
				>
					<Stack flexDirection={{ base: 'column', md: 'row' }} gap={{ base: 4, md: 16 }} alignItems={{ base: 'flex-start', md: 'center' }}>
						<Image w={{ base: '150px', md: '240px' }} h={{ base: '150px', md: '240px' }} src="/images/malmal-icon.png" />
						<VStack justifyContent={'flex-start'} alignItems={'flex-start'}>
							<Text color={'gray.800'} lineHeight={1.2} fontSize={useBreakpointValue({ base: '3xl', md: '5xl' })} fontFamily={'katuri'}>
								MALMAL Studio
							</Text>
							<VStack w="full" justifyContent={'center'} color={'black'} fontSize={useBreakpointValue({ base: 'md', md: 'lg' })}>
								<Text wordBreak={'keep-all'}>
									MALMAL Studio는 {'<차깨비 찻집>'}의 텀블벅 공개와 함께 만들어진{' '}
									<Text as="u" fontWeight={'bold'}>
										개발자 말말의 1인 개발 스튜디오
									</Text>
									입니다. 게임을 통해 눈이 즐겁고 동심이 가득한 세계를 표현하고자 하며, 주로 개발자의 취향이 가득 담긴 아기자기한 힐링 게임을 만들고
									있습니다. {'<차깨비 찻집>'}을 첫 작품으로 데뷔하였습니다.
								</Text>
							</VStack>

							<Button
								as={'a'}
								href="https://www.instagram.com/chaggebi"
								target="_blank"
								bgColor="black"
								color="white"
								borderRadius={'full'}
								_hover={{ bgColor: 'black', color: 'white' }}
							>
								<Text>
									공식 계정 바로가기 {'  '}
									<ExternalLinkIcon />
								</Text>
							</Button>
						</VStack>
					</Stack>
				</VStack>
			</VStack>
		</Center>
	);
};
