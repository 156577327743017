export default function PlayStoreBadge() {
	return (
		<svg width="100%" height="100%" viewBox="-2 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#prefix__clip0)">
				<path
					d="M11.556 8.341c-.334.333-.445.889-.445 1.556v24.555c0 .667.222 1.222.556 1.556l.11.11 13.779-13.777v-.222l-14-13.778z"
					fill="url(#prefix__paint0_linear)"
				/>
				<path
					d="M30 27.008l-4.556-4.556v-.333L30 17.563l.111.111 5.445 3.111c1.555.89 1.555 2.334 0 3.223l-5.556 3z"
					fill="url(#prefix__paint1_linear)"
				/>
				<path d="M30.111 26.897l-4.666-4.667-13.89 13.889c.556.556 1.334.556 2.334.111l16.222-9.333z" fill="url(#prefix__paint2_linear)" />
				<path d="M30.111 17.563L13.89 8.341c-1-.555-1.778-.444-2.333.111L25.444 22.23l4.666-4.667z" fill="url(#prefix__paint3_linear)" />
			</g>
			<defs>
				<linearGradient id="prefix__paint0_linear" x1={24.222} y1={9.686} x2={5.574} y2={28.332} gradientUnits="userSpaceOnUse">
					<stop stopColor="#00A0FF" />
					<stop offset={0.007} stopColor="#00A1FF" />
					<stop offset={0.26} stopColor="#00BEFF" />
					<stop offset={0.512} stopColor="#00D2FF" />
					<stop offset={0.76} stopColor="#00DFFF" />
					<stop offset={1} stopColor="#00E3FF" />
				</linearGradient>
				<linearGradient id="prefix__paint1_linear" x1={37.593} y1={22.231} x2={10.708} y2={22.231} gradientUnits="userSpaceOnUse">
					<stop stopColor="#FFE000" />
					<stop offset={0.409} stopColor="#FFBD00" />
					<stop offset={0.775} stopColor="orange" />
					<stop offset={1} stopColor="#FF9C00" />
				</linearGradient>
				<linearGradient id="prefix__paint2_linear" x1={27.586} y1={24.781} x2={2.299} y2={50.068} gradientUnits="userSpaceOnUse">
					<stop stopColor="#FF3A44" />
					<stop offset={1} stopColor="#C31162" />
				</linearGradient>
				<linearGradient id="prefix__paint3_linear" x1={8.108} y1={0.203} x2={19.4} y2={11.496} gradientUnits="userSpaceOnUse">
					<stop stopColor="#32A071" />
					<stop offset={0.069} stopColor="#2DA771" />
					<stop offset={0.476} stopColor="#15CF74" />
					<stop offset={0.801} stopColor="#06E775" />
					<stop offset={1} stopColor="#00F076" />
				</linearGradient>
			</defs>
		</svg>
	);
}
