import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
	Box,
	Center,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	Flex,
	HStack,
	IconButton,
	Image,
	Link,
	Stack,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

export const NavBar = () => {
	const { isOpen, onToggle, onClose } = useDisclosure();
	const [isTop, setIsTop] = useState(true);
	const boxColor = useMemo(() => {
		return isTop ? '' : 'white';
	}, [isTop]);
	const fontColor = useMemo(() => {
		return isTop ? 'white' : 'gray.800';
	}, [isTop]);
	const iconColor = useMemo(() => {
		return isTop ? 'white' : 'gray.600';
	}, [isTop]);
	const boxShadow = useMemo(() => {
		return isTop ? undefined : 'lg';
	}, [isTop]);

	useEffect(() => {
		const handleScroll = () => {
			window.scrollY > 0 ? setIsTop(false) : setIsTop(true);
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<Box w={{ base: '', md: 'full' }} pos={'fixed'} fontFamily={'notosans'} zIndex={'docked'}>
			{/* 데스크탑 */}
			<Box display={{ base: 'none', md: 'flex' }} bg={boxColor} boxShadow={boxShadow} color={fontColor} transition="all 0.2s ease-in-out">
				<Center w="full">
					<HStack w="full" maxW="1280px" minH={'80px'} px={{ base: 4 }} alignItems={'center'}>
						<HStack w="full" justify={{ base: 'center', md: 'start' }}>
							<Link
								as={RouterLink}
								to={'/'}
								p={2}
								fontSize={'sm'}
								fontWeight={500}
								_hover={{
									textDecoration: 'none',
								}}
								ml={'-16px'}
							>
								<Image minW="150px" maxW="200px" w="100%" src="/images/title.png" />
							</Link>
							<DesktopNav />
						</HStack>
						<Flex flex={{ base: 1 }} justify={{ base: 'end', md: 'end' }}></Flex>
					</HStack>
				</Center>
			</Box>
			{/* 모바일 */}
			<Box display={{ base: 'flex', md: 'none' }}>
				{!isOpen && (
					<Center
						w="full"
						m={3}
						p={3}
						borderRadius={'full'}
						bg={isTop ? 'transparent' : 'blackAlpha.600'}
						boxShadow={boxShadow}
						color={fontColor}
						transition="all 0.2s ease-in-out"
					>
						<IconButton
							onClick={onToggle}
							icon={<HamburgerIcon w={8} h={8} color="white" />}
							variant={'ghost'}
							colorScheme=""
							aria-label={'Toggle Navigation'}
						/>
					</Center>
				)}

				<Drawer placement={'left'} onClose={onClose} isOpen={isOpen} size="full">
					<DrawerContent backgroundColor={'rgba(17, 17, 17, 0.95)'}>
						<DrawerHeader>
							<IconButton
								position={'absolute'}
								pt={4}
								onClick={onClose}
								icon={<CloseIcon w={8} h={8} color="white" />}
								variant={'ghost'}
								colorScheme="black"
								aria-label={'Toggle Navigation'}
							/>
							<Link
								as={RouterLink}
								to={'/'}
								p={4}
								fontSize={'sm'}
								fontWeight={500}
								_hover={{
									textDecoration: 'none',
								}}
								display={'flex'}
								justifyContent={'center'}
								alignItems={'center'}
								onClick={onClose}
							>
								<Image minW="150px" maxW="240px" w="100%" src="/images/title.png" />
							</Link>
						</DrawerHeader>
						<DrawerBody>
							<MobileNav onCloseDrawer={onClose} />
						</DrawerBody>
					</DrawerContent>
				</Drawer>
			</Box>
		</Box>
	);

	function DesktopNav() {
		return (
			<HStack w="full" px={4} gap={6} fontFamily={'katuri'}>
				{NAV_ITEMS.map(navItem => {
					const linkParams = {} as any;
					if (navItem.sectionTo) {
						linkParams.as = ScrollLink;
						linkParams.to = navItem.sectionTo;
						linkParams.href = navItem.href ?? '#';
						linkParams.spy = true;
						linkParams.smooth = true;
						linkParams.offset = -80;
						linkParams.duration = 300;
					} else {
						linkParams.as = RouterLink;
						linkParams.to = navItem.href ?? '/';
						linkParams.target = '_blank';
					}
					return (
						<Flex key={navItem.label} alignItems={'center'}>
							<Link
								p={2}
								fontSize={'xl'}
								fontWeight={500}
								whiteSpace={'nowrap'}
								_hover={{
									textDecoration: 'none',
								}}
								{...linkParams}
							>
								{navItem.label}
							</Link>
						</Flex>
					);
				})}
			</HStack>
		);
	}
};

const MobileNav = ({ onCloseDrawer }: any) => {
	return (
		<Stack p={4} spacing={4}>
			{NAV_ITEMS.map(navItem => {
				const linkParams = {} as any;
				if (navItem.sectionTo) {
					linkParams.as = ScrollLink;
					linkParams.to = navItem.sectionTo;
					linkParams.href = navItem.href ?? '#';
					linkParams.spy = true;
					linkParams.smooth = true;
					linkParams.offset = 0;
					linkParams.duration = 300;
				} else {
					linkParams.as = RouterLink;
					linkParams.to = navItem.href ?? '/';
					linkParams.target = '_blank';
				}
				return (
					<Flex
						py={4}
						px={4}
						justify={'center'}
						align={'center'}
						_hover={{
							textDecoration: 'none',
						}}
						onClick={onCloseDrawer}
						{...linkParams}
					>
						<Text fontSize={'2xl'} fontWeight={800} color="white">
							{navItem.label}
						</Text>
					</Flex>
				);
			})}
		</Stack>
	);
};

interface NavItem {
	label: string;
	sectionTo?: string;
	subLabel?: string;
	children?: Array<NavItem>;
	href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
	{
		label: '게임 개요',
		sectionTo: 'synopsys',
		href: '#synopsys',
	},
	{
		label: '게임 소개',
		sectionTo: 'features',
		href: '#features',
	},
	{
		label: '쿠폰 사용',
		sectionTo: undefined,
		href: '/coupon',
	},
	{
		label: '개발자 소개',
		sectionTo: 'malmal-studio',
		href: '#malmal-studio',
	},
];
