import { Box, Center, HStack, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import AppStoreBadge from './AppStoreBadge';
import PlayStoreBadge from './PlayStoreBadge';

const maxWidth = '260px';
const height = { base: '48px', md: '64px' };

export function GotoOneStore() {
	return (
		<HStack
			as="a"
			w={'full'}
			h={height}
			maxW={maxWidth}
			cursor="pointer"
			justify={'center'}
			align={'center'}
			fontWeight={'normal'}
			px={6}
			py={4}
			borderRadius={'full'}
			boxShadow={'0px 5px 10px #555'}
			bg="white"
			href="https://m.onestore.co.kr/mobilepoc/apps/appsDetail.omp?prodId=0000776986"
			target="_blank"
		>
			<Box as={Center} boxSize={{ base: 5, md: 8 }}>
				<Image w="full" src="/images/one-store-badge.png" />
			</Box>
			<Text fontSize={useBreakpointValue({ base: 'md', md: '2xl' })} color="black" whiteSpace={'nowrap'}>
				One Store
			</Text>
		</HStack>
	);
}

export function GotoGooglePlay() {
	return (
		<HStack
			as="a"
			w={'full'}
			h={height}
			maxW={maxWidth}
			cursor="pointer"
			justify={'center'}
			align={'center'}
			fontWeight={'normal'}
			px={6}
			py={4}
			borderRadius={'full'}
			boxShadow={'0px 5px 10px #555'}
			bg="white"
			href="https://play.google.com/store/apps/details?id=com.malmal.teablin&hl=ko"
			target="_blank"
		>
			<Box as={Center} boxSize={{ base: 8, md: 10 }} ml={-2}>
				<PlayStoreBadge></PlayStoreBadge>
			</Box>
			<Text fontSize={useBreakpointValue({ base: 'md', md: '2xl' })} color="black" whiteSpace={'nowrap'}>
				Google Play
			</Text>
		</HStack>
	);
}

export function GotoAppStore() {
	return (
		<HStack
			as="a"
			w={'full'}
			h={height}
			maxW={maxWidth}
			cursor="pointer"
			justify={'center'}
			align={'center'}
			fontWeight={'normal'}
			px={6}
			py={4}
			borderRadius={'full'}
			boxShadow={'0px 5px 10px #555'}
			bg="white"
			href="https://apps.apple.com/kr/app/차깨비-찻집/id6563151083"
			target="_blank"
		>
			<Box as={Center} boxSize={{ base: 8, md: 10 }} ml={-2}>
				<AppStoreBadge></AppStoreBadge>
			</Box>
			<Text fontSize={useBreakpointValue({ base: 'md', md: '2xl' })} color="black" whiteSpace={'nowrap'}>
				App Store
			</Text>
		</HStack>
	);
}
